@import "../../../Style/colors.scss";

.absence-manager {
	min-height: 600px;
	position: relative;
	min-width: 600px;
	.card-content {
		height: 100%;
	}
}
