@import "../../Style/colors.scss";

.login-page {
	height: 100vh;
	width: 100vw;
	display: flex;
	.left-section {
		background: url("./login-img.jpg");
		background-size: cover;
		background-position: center;
		flex-grow: 1;

		@media screen and (max-width: 900px) {
			display: none;
		}

		.left-section-layer {
			width: 100%;
			height: 100%;
			background: $loginImageLayer;
		}
	}
	.right-section {
		position: relative;
		z-index: 1;
		width: 500px;
		background: $loginMainBackground;
		height: 100%;
		box-shadow: $loginShadow;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@media screen and (max-width: 900px) {
			width: 100%;
		}

		& > div {
			padding: 20px;
		}

		.onedash-input-container {
			display: block;
		}
	}
	.title {
		margin-bottom: 30px;
		padding: 10px;
	}

	.additional-links {
		margin: 5px;
		margin-top: 35px;
		a,
		button {
			border: none;
			background: none;
			cursor: pointer;
			color: $linkColor;
			transition: $baseEaseTransition;
			&:hover {
				opacity: 0.7;
			}
		}
	}
}
