/* Colors */

$pageBackground: #f1f1f1;

$primary: #e88760;

$completed: #4fc486;
$pending: #fdc266;
$rejected: #b64c22;
$blue: #106fdf;

$navBackground: #354555;
$navIconColor: white;
$logoBackground: #313f4d;
$headerBackground: white;
$headerCurrentPageColor: #e88760;
$navElementBackground: rgba(0, 0, 0, 0.3);
$navAppNameColor: white;

$dropdownBackground: white;

$cardBackground: white;
$cardDividerColor: #e9e9e9;

$textColor: #393939;
$linkColor: #83b3db;
$labelColor: #aeaeae;
$primaryFormButton: #2ef189;
$secondaryFormButton: #e9e9e9;
$standardFormButton: #106fdf;
$deleteFormButton: #e88760;
$disabledBtn: #e9e9e9;

//Dialog
$dividerColor: #e9e9e9;

$h2Color: #354555;
$h2BorderColor: #e9e9e9;

//Input
$inputBorder: #c7c7c7;
$inputBorderFocus: #9c9c9c;
$inputCheckSuccess: #2ef189;
$inputCheckFailed: #e88760;
$inputLabelColor: #aeaeae;
$inputColor: #393939;
$inputRequiredColor: #c44234;
$inputBackground: none;
$inputHintBackground: #fafafa;
$inputHintBorder: #ebebeb;
$inputHintTextColor: #393939;
$inputHintIndicatorBorderColor: #393939;
$inputSubmitColor: white;
$inputEditColor: #106fdf;

$fieldSetColor: #ebebeb;

$headdingTextColor: #707070;

// Dialog
$dialogBackground: white;
$dialogTextColor: #393939;
$dialogFooterBorder: #ebebeb;
$dialogFooterColor: #777777;
$dialogFooterButtonShadow: 1px 1px 5px rgba(0, 0, 0, 0.25);

//Navigation
$navItemLoading: #e88760;
$navItemHoverColor: #e88760;

$callbackSpinnerColor: #e88760;

$loginMainBackground: white;
$loginImageLayer: rgba(0, 0, 0, 0.0);

/* Table */
$tableHeader: #354555;
$tableHeaderColor: white;
$tableBorderColor: #ebebeb;
$tableRowHover: #ebebeb;

/* Shadows */
$dialogShadow: 1px 1px 3px black;
$navigationShadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
$cardShadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
$loginShadow: -2px 0px 6px rgba(0, 0, 0, 0.4);

/* Calendar */
$calendarTileBackground: #f5f5f5;
$calendarTileWeekendBackground: #e2e2e2;
$calendarTileHoverBackground: #d0e8fc;
$calendarBorderToday: #2477bb;
$calendarBorderBooked: $completed;
$calendarBorderPending: $pending;
$headerTextColor: #1d4d7a;

/* Transitions */
$baseEaseTransition: 0.3s all ease-in-out;
$fastEaseTransition: 0.15s all ease-in-out;

// IOS Styles
$iosWhiteBackground: #f2f2f7;
$iosBlue: #007aff;
