@import "../../../Style/colors.scss";

.dashboard-container {
	.dashboard-headder {
		position: fixed;
		top: 0;
		left: 80px;
		height: 60px;
		background: $headerBackground;
		width: calc(100vw - 80px);
		box-shadow: $navigationShadow;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 50;
		.header-icons {
			position: fixed;
			right: 0px;
			padding-right: 40px;
			font-size: 1.5rem;
			i,
			svg {
				cursor: pointer;
				transition: 0.3s all ease-in-out;

				&:hover {
					opacity: 0.7;
				}
			}

			.dropdown__content {
				background: $dropdownBackground;
				padding: 10px;
				font-size: 1rem;
				right: 30px;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
				margin-top: 10px;
				border-radius: 4px;
				width: 250px;

				img {
					width: 40px;
					height: 40px;
					border-radius: 20px;
				}

				p {
					margin: 0px;
					color: $textColor;

					&.profile-name {
						font-weight: bold;
					}
				}
				a,
				li,
				p {
					transition: 0.3s all ease-in-out;
					color: $textColor;
				}

				li {
					padding: 10px;
					cursor: pointer;
					border-bottom: 1px solid $dividerColor;

					&:hover,
					&:hover p {
						color: $primary;
					}

					div {
						margin-left: 10px;
					}
					a {
						text-decoration: none;
						display: flex;
						align-items: center;
					}

					&:last-of-type {
						border-bottom: none;
					}
				}
			}
		}

		@media (max-width: 768px) {
			left: 0;
			width: 100%;
		}

		.currentPage {
			font-size: 1.3rem;
			font-weight: bold;
			color: $headerCurrentPageColor;
		}

		.nav-buttons {
			position: fixed;
			top: 0;
			left: 0;
			width: 80px;
			height: 60px;
			background: $logoBackground;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 768px) {
				display: none;
			}

			i,
			svg {
				color: white;
				font-size: 1rem;
				margin: 8px;
				cursor: pointer;
				transition: 0.3s all ease-in-out;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	.dashboard-content {
		padding: 80px 20px 20px 100px;
		width: calc(100% - 120px);

		.application-error {
			text-align: center;
			font-size: 1.3em;
			margin-top: 30vh;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}

		@media (max-width: 768px) {
			padding: 80px 20px 20px 20px;
			width: calc(100% - 40px);
		}

		.dashboard-content-item {
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 800px) {
				.onedash-card {
					flex-grow: 1;

					.card-headding {
						padding: 0px 5px 2.5px 5px;
					}
				}
				.card-content {
					display: inline-block;
					margin-left: auto;
					margin-right: auto;
					width: 100%;
				}
			}

			&.dashboard-content-item-centered {
				margin-left: auto;
				margin-right: auto;
				max-width: 900px;
				justify-content: center;
			}

			&.dashboard-content-item-process {
				max-width: 600px;
				.onedash-card {
					width: 100%;
				}
			}
			&.dashboard-content-item-table {
				max-width: 1500px;
				width: 100%;
			}
			&.dashboard-content-item-formular {
				max-width: 900px;
				width: 100%;
				background: $cardBackground;
				box-shadow: $cardShadow;
				justify-content: space-between;
				margin-bottom: 100px;

				.onedash-card {
					box-shadow: none;
					background: none;
				}
			}

			&.dashboard-content-item-hidden {
				opacity: 0;
			}
			&:not(.dashboard-content-item-hidden) {
				animation: fadein 0.5s ease-out normal;
			}
		}
		.card-divider {
			border-bottom: 1px solid $cardDividerColor;
			width: calc(100% - 100px);
			margin-left: 50px;
			margin-top: 30px;
			margin-bottom: 40px;
		}

		.card-wrapper {
			width: 100%;
			& > h1 {
				margin: 30px;
				font-size: 1.7rem;
				color: #354555;
				font-weight: lighter;
				margin-bottom: 10px;
				border-bottom: 1px solid #e9e9e9;
				padding: 0px 0px 2.5px 5px;
			}
			.card-wrapper-content {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				& > div {
					flex-grow: 1;
				}
			}
		}
	}
}
.starting-application {
	h1 {
		margin-top: 30vh;
		text-align: center;
		font-size: 1.2rem;
	}
}
.dashboard-second-navigation {
	height: 50px;
	display: flex;
	justify-content: space-around;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 20;
	padding: 0px 10px;

	.navigation-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #343333;
		opacity: 0.98;
		border-radius: 30px;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
	}

	.nav-list {
		display: flex;
		z-index: 2;
		align-items: center;
		ul {
			display: flex;
			li {
				list-style: none;

				button,
				a {
					background: none;
					border: none;
					color: white;
					cursor: pointer;

					i,
					svg {
						color: white;
						font-size: 1.2rem;
						transition: 0.3s all ease-in-out;
						&:hover {
							color: $navItemHoverColor;
						}
					}
				}
			}
		}
	}
}

@media print {
	.dashboard-second-navigation {
		display: none !important;
	}
}

@keyframes fadein {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	70% {
		transform: scale(1.05);
		opacity: 0.8;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
