@import "../../../Style/colors.scss";

.property-box {
	position: absolute;
	background: #d8c8b3;
	color: white;
	width: 385px;
	z-index: 3;
	left: 50%;
	border-radius: 6px;
	margin-left: -205px;
	padding: 10px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	margin-top: 15px;

	.property-textarea-container {
		display: flex;
		flex-direction: column;
		margin: 5px 0px;
	}
	.lang-indicator {
		width: 100%;
		background: rgba(0, 0, 0, 0.8);
		padding: 5px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
	}

	.onedash-style-one.onedash-tag-container {
		margin: 0px;
		text-align: left;
		.react-tags {
			max-width: 500px;
		}

		.react-tags__suggestions {
			color: $textColor;
		}
	}

	textarea {
		overflow: hidden;
		border: none;
		display: block;
		background: none;
		color: white;
		resize: none;
		padding: 5px;
		width: calc(100% - 10px);
		background: rgba(0, 0, 0, 0.5);
		height: 70px;
		font-family: "Open Sans";
		text-align: center;

		&::placeholder {
			color: white;
		}
	}
	&:after {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: #d8c8b3;
		border-width: 10px;
		margin-left: -10px;
	}
}
.property-close-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 3;
	height: 100vh;
	cursor: default;
}
