@import "../../Style/colors.scss";

.room-invoice {
	.onedash-select-container {
		width: 100%;
	}
	.room-selection {
		.room {
			display: flex;
			flex-wrap: wrap;

			.room-types {
				display: flex;
				& > div {
					margin-right: 10px;
				}
			}

			& > div {
				margin: 3px 3px;
			}

			.room-selector {
				p {
					margin: 0px;
					font-size: 1rem;
					font-weight: lighter;
				}
				border: 1.5px solid $inputBorder;
				padding: 5px 10px;
				border-radius: 4px;
				cursor: pointer;
				transition: $fastEaseTransition;

				&.active {
					background: $inputCheckSuccess;
					border: 1.5px solid $inputCheckSuccess;
					color: white;
				}
			}
		}

		.room-name {
			display: flex;
			align-items: center;
			width: 150px;
			font-weight: bold;
		}
	}

	.range-picker-input {
		border: none;
		width: auto !important;

		input {
			border: 1.5px solid $inputBorder !important;
			color: $inputColor;
			font-size: 1rem;
			border-radius: 4px;
			padding: 10px 12.5px;
			transition: 0.3s all ease-in-out;
			background: none;
			width: 128px !important;
			margin: 5px;
		}
		.range-picker-input__icon {
			display: none;
		}
	}

	.form-group {
		margin: 5px;
		display: inline-block;

		label {
			display: block;
			color: $labelColor;
			font-size: 0.9rem;
			margin-left: 2px;
			max-width: 120px;
		}
		input {
			display: block;
			border: 1.5px solid $inputBorder;
			color: $inputColor;
			border-radius: 4px;
			padding: 10px 12.5px;
			transition: 0.3s all ease-in-out;
			background: none;
			width: 100px;
		}
	}

	.settings {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.additional-data {
			.form-group {
				input {
					width: calc(100% - 30px);
				}
				margin-bottom: 30px;
			}
		}

		.onoffswitch {
			input {
				display: none;

				&:checked + label {
					background: $inputCheckSuccess;
					border: 1.5px solid $inputCheckSuccess;
					color: white;
				}
			}
			label {
				border: 1.5px solid $inputBorder;
				width: 150px;
				padding: 5px 10px;
				transition: $fastEaseTransition;
				margin: 5px;
				border-radius: 4px;
				font-size: 1rem;
				font-weight: lighter;
				display: block;
				cursor: pointer;
			}
		}

		& > div:nth-child(3) {
			width: 250px;
		}
		& > div:nth-child(2) {
			width: 380px;
		}
		& > div:nth-child(1) {
			width: 220px;
			input {
				width: calc(100% - 20px);
			}
		}
	}
}
