@import "../../../Style/colors.scss";

.card-designer-navigation {
	height: 50px;
	display: flex;
	justify-content: space-around;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 20;
	padding: 0px 10px;

	.navigation-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #343333;
		opacity: 0.98;
		border-radius: 30px;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
	}

	.nav-list {
		display: flex;
		z-index: 2;
		align-items: center;
		ul {
			display: flex;
			li {
				list-style: none;
				margin: 0px 10px;

				button,
				a {
					background: none;
					border: none;
					color: white;
					cursor: pointer;

					i,
					svg {
						color: white;
						font-size: 1.2rem;
						transition: 0.3s all ease-in-out;
						&:hover {
							color: $navItemHoverColor;
						}
					}
				}
			}
		}
	}
}
@media print {
	.card-designer-navigation {
		display: none;
	}
}
