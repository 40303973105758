@import "../../../Style/colors.scss";

.mediathek {
	.infinite-scroll-component__outerdiv {
		margin: 0px 10px;
	}
	.movie-delete {
		margin: 20px 10px;
		background: none;
		color: $rejected;
		border: none;
		padding: 0px;
		cursor: pointer;
	}
	.flex {
		display: flex;
	}
	.entry {
		margin: 5px 10px;
		.label {
			font-weight: bold;
		}
	}
	.react-tags {
		max-width: 380px important;
	}
	.onedash-form {
		.onedash-submit-container {
			display: inline-block;
		}
	}
	.pending {
		color: $textColor;
		.indicator > span {
			background: $pending;
			&:hover {
				background: $completed;
			}
		}
	}
	.accepted .indicator > span {
		background: $completed;
		&:hover {
			background: $rejected;
		}
	}
	.declined .indicator > span {
		background: $rejected;
		&:hover {
			background: $completed;
		}
	}
	.Collapsible {
		width: calc(100% - 4px);
		position: relative;

		.indicator {
			position: absolute;
			right: 0px;
			width: 100px;
			text-transform: uppercase;
			font-weight: bold;
			top: 0;
			height: 140px;
			color: white;
			border-left: 2px solid #e9e9e9;

			span {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0px;
				width: 100%;
				height: 100%;
				text-align: center;
				transition: 0.15s all ease-in-out;
			}
		}
	}
	.Collapsible__trigger {
		height: 120px;
		width: calc(100% - 140px);
		.trigger {
			justify-content: flex-start;

			img {
				width: 200px;
				border: 1px solid rgba(0, 0, 0, 0.7);
			}
			.title {
				font-size: 1.1rem;
				color: $textColor;
			}
			& > div {
				margin-left: 30px;
			}
			.tags {
				text-transform: uppercase;
				color: $blue;
			}
		}
	}
	.end-text {
		font-size: 1.2rem;
		text-align: center;
		margin-top: 50px;
	}
}
