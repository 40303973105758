.privacy-impress {
	font-family: Raleway, sans-serif;
	color: #335d59;
	h1 {
		font-size: 2rem;
		line-height: 25px;
		margin-bottom: 10px;
		padding: 0;
		margin: 0;
		color: #e4685b;
		margin-top: 30px;
	}
	h2 {
		font-size: 1.5rem;
		margin-top: 25px;
		margin-bottom: 0;
	}
	h3 {
		margin: 20px 0 0 0;
	}
	p {
		font-size: 1rem;
		line-height: 22px;
		margin: 5px 5px;
	}
}
.dialog-container.update-available {
	.dialog {
		max-width: 500px;

		p {
			margin: 10px;
		}
		button {
			margin-top: 20px;
		}
	}
}
.input-icon-right {
	svg {
		position: absolute;
		font-size: 1.5rem;
		right: 10px;
		top: 15px;
		color: #393939;
	}
}

.table-doubleRow {
	.dialog-content {
		.onedash-form {
			margin-top: -10px;
			& > div {
				display: flex;
				flex-wrap: wrap;

				& > div {
					width: 420px;
					margin-right: 20px;
				}
			}
		}
	}
}
