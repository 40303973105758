@import "../../../Style/colors.scss";
@font-face {
	font-family: Champagne;
	src: url(Champagne_Limousines.ttf);
}
@font-face {
	font-family: Wingding;
	src: url(Wingding.ttf);
}
@import url("https://fonts.googleapis.com/css?family=Eczar|Raleway:300,400,500,800,900&display=swap");

$inputBorderColor: #eeeeee;
$inputTextColor: #3b3a36;
$h1Color: #e88760;

.card-list {
	z-index: 2;
	position: relative;
}
.item-name,
.menu-wine-recommendation,
.additional-text,
.clickable-text {
	cursor: pointer;
	transition: 0.3s all ease-in-out;
	overflow-wrap: break-word;
	&:hover {
		color: #56a7c6 !important;
	}
}
.card-item {
	position: relative;
}
.card h1 {
	transition: 0.3s all ease-in-out;
	cursor: pointer;
	&:hover {
		color: #56a7c6;
	}
}
.master-card {
	&.inhaltsstoffe-print {
		&.master-card.standard-card .card-list {
			padding: 50px 20px 15px 20px;
		}

		&.master-card.standard-card {
			.item-divider {
				display: none !important;
			}
			.card-item {
				margin-top: 10px;
			}
		}
		&.master-card.standard-card .card-list .card.drink .card-item {
			margin-top: 0px;
		}
	}
	&.drink-card {
		&.inhaltsstoffe-print .main-item:not(.border-disabled)::before {
			border-bottom: none !important;
		}
		&.kennzeichnungen-print .item-name {
			display: inline-block;
		}
		.item-zusatzstoffe {
			display: block;
			background: white;
			font-size: 0.8rem;
		}
	}
	&.standard-card {
		.page-zusatzstoffe {
			h1 {
				margin: 0;
				padding: 0;
				font-family: Eczar;
				font-size: 2rem;
				font-weight: 400;
				text-decoration: underline;
				text-align: center;
				margin-top: 25px;
			}
			.num {
				width: 30px;
				font-family: "Open Sans";
				font-weight: bold;
				font-size: 1rem;
			}
			.text {
				flex-grow: 1;
			}
			ul {
				margin-top: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			li {
				font-family: "Champagne";
				font-size: 1.3rem;
				display: flex;
				justify-content: space-between;
				margin: 10px;
				width: 350px;
				margin-left: auto;
				margin-right: auto;
				text-align: left;
			}
		}
	}
	&.drink-card {
		.page-zusatzstoffe {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			text-align: center;

			h1 {
				font-family: "Raleway";
				padding: 0px 25px 2px 25px;
				display: inline-block;
				border-bottom: 2px solid rgba(0, 0, 0, 0.6);
				margin: 5px 0px 7px 0px;
				font-size: 1.3rem;
				font-weight: 500;
				text-transform: uppercase;
			}
			.num {
				width: 30px;
				font-weight: bold;
				font-size: 1rem;
			}
			.text {
				flex-grow: 1;
			}
			ul {
				margin-top: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			li {
				font-family: "Raleway";
				font-size: 1rem;
				display: flex;
				justify-content: space-between;
				margin: 5px;
				width: 100%;
				text-align: left;
			}
		}
	}

	&.standard-card {
		color: #394641;
		.card-list {
			padding: 100px 20px 15px 20px;
			.item-number {
				font-family: "Champagne";
			}
			.item-name,
			.item-price {
				font-size: 1.3rem;
				line-height: 2rem;
				font-family: "Champagne";
			}

			.item-zusatzstoffe {
				display: block;
				font-family: "Open Sans";
				font-size: 0.9rem;
				max-width: 350px;
				margin-left: auto;
				margin-right: auto;
			}

			.card {
				width: 100%;
				h1 {
					margin: 0;
					padding: 0;
					font-family: Eczar;
					font-size: 2rem;
					font-weight: 400;
					text-decoration: underline;
					text-align: center;
					margin-top: 25px;
				}
				.item-divider {
					margin: 15px;
					font-size: 1rem;
					font-family: Wingding;
					text-align: center;
					content: "fff";
					display: block;
					font-size: 1rem;
					line-height: 1rem;
				}
				.card-items {
					margin-top: 10px;
				}
				.person-num {
					font-family: "Champagne";
					font-size: 1rem;
					font-weight: 700;
				}
				.menu-price {
					font-size: 1.6rem;
					font-weight: 700;
					font-family: "Champagne";
					margin-top: 20px;
				}
				.corresponding-wines {
					font-family: "Champagne";
					font-size: 1.2rem;
					p {
						margin-bottom: 3px;
					}
					span {
						font-size: 1.4rem;
						font-weight: bold;
					}
				}
				.menu-wine-recommendation {
					text-align: center;
					margin-top: 20px;
					p:nth-child(1) {
						font-family: "Champagne";
						font-size: 1.3rem;
						text-decoration: underline;
					}
					p:nth-child(2) {
						font-family: "Champagne";
						font-size: 1.2rem;
						margin-top: -15px;
					}
				}
				.additional-text {
					font-family: "Champagne";
					margin-top: 20px;
					text-align: center;
					font-size: 1.1rem;
					font-weight: bold;
				}
				&.eat {
					margin-left: -45px;
					.item-price {
						width: 70px;
						text-align: left;
						position: absolute;
						right: -70px;
						top: 0;
						height: 100%;
					}
					.item-name-container {
						text-align: center;
					}
					.card-items > div {
						display: flex;
						align-items: center;
						flex-direction: column;
					}
					.main-item {
						position: relative;

						width: 500px;
					}
				}

				&.menu {
					text-align: center;

					.item-name {
						max-width: 430px;
						display: inline-block;
					}
				}

				&.drink {
					.item-name-container {
						position: relative;
					}
					.card-items {
						margin-top: 20px;
					}
					.card-item {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.main-item {
						display: flex;
						justify-content: space-between;
						margin: 5px;
						width: 400px;
						.item-name {
							text-align: left;
						}
					}

					.item-divider {
						display: none;
					}
				}
			}
		}
	}

	&.drink-card {
		font-size: 0.9rem;

		.page-title {
			position: absolute;
			left: 30px;
			top: 10px;
			font-weight: 900;
			text-transform: uppercase;
			font-size: 1.7rem;
			padding: 0px 20px 5px 20px;
			border-bottom: 2px dotted #ca4343;
			color: #3b3a36;
			margin: 20px 0px;
		}
		.item-has-description {
			&.main-item {
				display: block !important;

				.item-name {
					font-weight: 100;
					display: block;
					font-size: 0.8rem;
					color: #696969;
					.first-line {
						font-size: 0.85rem;
						color: #373737;
						font-weight: 500;
					}
				}
				.item-amount-price > div {
					justify-content: flex-start !important;
				}

				&[data-card-index="0"],
				&[data-card-index="1"] {
					.item-name-container {
						text-align: right;
					}
					.item-amount-price > div {
						justify-content: flex-end !important;
					}
				}
			}
		}
		.card-list.centered-list {
			margin: 70px 50px 40px 50px;
			height: calc(100% - 110px);
			& > * {
				font-family: "Raleway", sans-serif;
				color: #373737;
			}
			color: #3b3a36;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			text-align: center;
			& .menu {
				.item-divider {
					margin: 15px;
					font-size: 1.1rem;
					font-family: Wingding;
					text-align: center;
					display: block;

					line-height: 1rem;
				}
				.item-name {
					font-size: 1.1rem;
					font-weight: 300;
				}
				.menu-price {
					font-weight: 600;
					font-size: 1.3rem;
					margin-top: 25px;
				}
				.menu-wine-recommendation {
					font-weight: 300;
					p:nth-child(1) {
						text-decoration: underline;
						font-size: 1.2rem;
					}
					p:nth-child(2) {
						margin-top: -15px;
					}
				}
				h1 {
					color: #914d28;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 1.6rem;
					padding: 0px 20px 5px 20px;
					display: inline-block;
					border-bottom: 2px dotted #ca4343;
				}
				.card-item {
					margin: 10px;
				}
			}

			& .drink {
				.card-item {
					width: 300px;
				}
				&:first-of-type {
					margin-top: -100px;
				}
				.main-item {
					display: block !important;
					text-align: center !important;
				}
				.item-name {
					width: 230px !important;
				}
				.item-price {
					padding-left: 0px !important;
				}
				.item-amount-price {
					margin-top: 2.5px;
					justify-content: center;
					align-items: center;
					font-size: 0.9rem;
				}
				.price-buttons {
					top: 1px;
				}
				.item-name-container {
					display: flex;
					justify-content: center;
				}
				.item-amount-price > div {
					display: flex;
					position: relative;
					width: 100px;
					justify-content: space-between;
				}
			}
			& .eat,
			& .drink {
				h1 {
					padding: 0px 25px 2px 25px;
					display: inline-block;
					border-bottom: 2px solid rgba(0, 0, 0, 0.6);
					margin: 5px 0px 7px 0px;
					font-size: 1.3rem;
					font-weight: 500;
					text-transform: uppercase;
				}
				.item-divider {
					display: none;
				}
				.item-price {
					text-align: right;
					font-weight: bold;
				}
				.item-price,
				.item-name {
					background: white;
					z-index: 1;
				}
				.item-price {
					padding-left: 5px;
					display: flex;
					align-items: flex-end;
				}

				.item-name {
					padding-right: 5px;
				}
				.main-item {
					display: flex;
					justify-content: space-between;
					text-align: left;
					margin: 15px;
					&:not(.border-disabled):before {
						content: "";
						position: absolute;
						bottom: 2px;
						z-index: -1;
						width: calc(100% - 30px);
						height: 0;
						line-height: 0;
						border-bottom: 1px dotted #ca4343;
					}
				}
				.additional-text {
					margin-top: 50px;
				}
			}
		}
		.price-buttons {
			position: absolute;
			right: -30px;
			font-size: 0.8rem;
			top: 2px;

			i,
			svg {
				cursor: pointer;
				transition: 0.3s all ease-in-out;
				margin: 0px 1px;
			}
			i:hover {
				transform: rotate(90deg);
			}
			.fa-minus-circle {
				color: #ca4343;
			}

			.fa-plus-circle {
				color: #7fcc76;
			}
		}
		.card-list.grid-list {
			margin: 75px 50px 15px 50px;
			height: calc(100% - 90px);
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			.card {
				.item-price {
					font-weight: bold;
					padding-left: 5px;
				}
				.item-amount {
					font-size: 0.8rem;
					font-weight: 400;
					line-height: 1.2rem;
					text-align: right;
					max-width: 45px;
				}
				text-align: left;
				h1 {
					padding: 0px 25px 2px 5px;
					display: inline-block;
					border-bottom: 2px solid rgba(0, 0, 0, 0.6);
					margin: 5px 0px 7px 0px;
					font-size: 1.3rem;
					font-weight: 500;
					text-transform: uppercase;
				}
				padding: 5px 0px 10px 20px;
				.card-items {
					width: 90%;
				}

				&:nth-child(1),
				&:nth-child(2) {
					display: flex;
					align-items: flex-end;
					flex-direction: column;
					text-align: right;
					border-left: 0px;
					border-right: 1px solid rgba(0, 0, 0, 0);
					padding-left: 0px;
					padding-right: 20px;
					h1 {
						padding: 0px 5px 2px 25px;
					}
				}
				width: calc(50% - 20px);
			}
			& > * {
				font-family: "Raleway", sans-serif;
				color: #373737;
			}

			.card-item {
				display: inline-block;
				width: 100%;
			}
			.eat {
				.item-price {
					display: flex;
					align-items: flex-end;
				}
				.main-item {
					margin: 10px 0px;
				}
				.main-item::before {
					top: auto !important;
					bottom: 13px !important;
				}
				&:nth-child(3) {
					display: flex;
					align-items: flex-end;
					flex-direction: column;
					text-align: right;
					border-left: 0px;
					border-right: 1px solid rgba(0, 0, 0, 0);
					padding-left: 0px;
					padding-right: 20px;
					h1 {
						padding: 0px 5px 5px 25px;
					}
				}
			}

			.item-divider {
				display: none;
			}
			.item-name-container {
				text-align: left;
			}
			.item-amount-price {
				.item-price {
					width: 50px;
					padding-left: 0px;
				}
				& > div {
					justify-content: flex-end;
					display: flex;
					position: relative;

					& > div {
						margin: 0px 2.5px;
					}
				}
			}
			.item-name {
				background: white;
				padding-right: 2.5px;
				z-index: 1;
			}
			.item-name-container {
				position: relative;
			}
			.item-amount-price,
			.item-price {
				background: white;
				z-index: 1;
			}
			.property-box {
				margin-top: 10px;
			}

			.main-item {
				display: flex;
				width: 100%;
				justify-content: space-between;
				margin: 2px;
				&:not(.border-disabled):before {
					content: "";
					position: absolute;
					top: 1rem;
					width: 100%;
					z-index: -1;
					height: 0;
					line-height: 0;
					border-bottom: 1px dotted #ca4343;
				}
			}
			.additional-text {
				text-align: center;
				font-weight: 500;
				margin-top: 50px;
			}
		}
		.main-item.border-disabled:not(.item-has-description) {
			.item-amount-price {
				display: flex;
				flex-direction: column;
				.item-price {
					font-weight: bold;
				}
			}
			.item-name {
				font-weight: 300;
				display: block;
				font-size: 0.8rem;
				width: 180px;
				.first-line {
					font-size: 0.95rem;
					font-weight: 500;
					margin-bottom: 1px;
					display: inline-block;
				}
			}
		}
	}
}

.detail-dialog,
.pages-dialog,
.settings-dialog {
	*::-webkit-scrollbar {
		width: 0;
	}

	*::-webkit-scrollbar-track {
		display: none;
	}
	.select-box.dark .select-box__input-text {
		background-color: white;
	}
	.select-box.dark .select-box__option {
		background-color: white;
	}
	h1 {
		border-bottom: 2px solid white;
		padding: 0px 10px 5px 5px;
		margin: 10px 10px 0px 0px;
		font-size: 1.6rem;
		color: $h1Color;
	}
	h2 {
		color: $h2Color;
		font-weight: lighter;
		margin-bottom: 40px;
		border-bottom: 1px solid $h2BorderColor;
		padding: 0px 0px 2.5px 5px;
		margin: 0px 0px 20px 0px;
	}
	.detail-dialog-item-list {
		max-height: 400px;
	}
	.language-selection {
		.onedash-form {
			margin: 0px;
		}
		.onedash-input-container {
			margin: 0px;
			width: 100%;
		}
	}

	.detail-dialog-content {
		overflow: auto;
		&::-webkit-scrollbar {
			width: 0px;
		}
		&::-webkit-scrollbar-track {
			display: none;
		}
		margin-top: 10px;
		& > div {
			display: flex;
			padding: 5px;

			flex-wrap: wrap;
			&:last-of-type {
				border-bottom: none;
			}
			& > div:nth-child(1) {
				padding-right: 20px;
				width: 350px;
			}
			& > div:nth-child(2) {
				padding-left: 20px;
				width: 300px;
			}
		}
	}
	.pages-dialog-content {
		.page-list {
			max-height: 400px;
			overflow: auto;
		}
		.page-card-detail-list {
			height: 200px;
			overflow: auto;
			.deleteItem {
				display: block;
			}
		}
		.page-detail-view {
			width: 300px;
			padding-left: 10px;
			border-left: 0.5px solid rgba(0, 0, 0, 0.2);

			& > div {
				padding: 5px;
				margin-bottom: 20px;
			}

			.page-name-edit {
				margin-top: 10px;
			}
		}
		margin: 5px;
		margin-top: 15px;
		display: flex;
		.page-card-list {
			& > div {
				padding: 5px;
				margin-bottom: 20px;
			}
			padding-right: 10px;
			border-right: 0.5px solid rgba(0, 0, 0, 0.2);
			width: 250px;
		}
	}
	.sortableListItem {
		&.clickable {
			cursor: pointer;
		}

		&.selected {
			color: $h1Color;
			.deleteItem {
				display: block;
			}
		}
	}
}
.detail-dialog-type-selection,
.detail-dialog-page-selection {
	margin-top: -20px;
}
.detail-dialog-edit-name-container {
	position: relative;
	.lang-indicator {
		position: absolute;
		display: block;
		right: -4px;
		border-radius: 0 4px 4px 0;
		line-height: 2.7rem;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		font-size: 1rem;
		width: 50px;
		height: 100%;
		text-align: center;
		text-transform: uppercase;
		font-family: Roboto;
		font-weight: 400;
		color: #fff;
	}
	input {
		border: 2px solid $inputBorderColor;
		color: $inputTextColor;
		border-radius: 4px;
		overflow: hidden;
		margin: 0 0 10px;
		font-family: Roboto;
		width: calc(100% - 60px);
		resize: none;
		padding: 10px 50px 10px 10px;
		display: block;
	}
}
.detail-dialog-settings ul li,
.settings-list ul li {
	z-index: 1 !important;
}
.detail-dialog-settings .onedash-input-container,
.settings-list .onedash-input-container,
.page-options .onedash-input-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 0px;
	margin: 0px;
	.onedash-label {
		position: relative;
		width: 150px;
		display: block;
		top: 0px;
		-webkit-user-select: none; /* Chrome all / Safari all */
		-moz-user-select: none; /* Firefox all */
		-ms-user-select: none; /* IE 10+ */
		user-select: none;
	}
}

.sortableListItem,
.detail-dialog .detail-dialog-settings ul li,
.detail-dialog .add-item,
.pages-dialog .add-item,
.settings-list ul li,
.page-options ul li {
	z-index: 101;
	border: 2px solid $inputBorderColor;
	display: flex;
	align-items: center;
	padding: 5px 20px;
	margin: 10px 2px;
	border-radius: 2px;
	position: relative;
	cursor: default;
	font-size: 0.9rem;

	p {
		margin: 5px 0px 5px 30px;
		width: 150px;
	}
	.fa-grip-lines {
		cursor: grab;
		margin-right: 10px;
		margin-left: -10px;
	}
	.deleteItem {
		border: none;
		padding: 0;
		margin: 0;
		background: none;
		position: absolute;
		top: 6px;
		right: 7.5px;
		color: #e95a5a;
		transition: 0.3s all ease-in-out;
		cursor: pointer;
		&:hover {
			transform: rotate(90deg);
			opacity: 0.8;
		}
	}
}
.add-item {
	justify-content: center;
	color: #7fcc76;
	margin-top: 20px !important;
	&:hover {
		box-shadow: 0 0 0.2rem #000 !important;
	}
	i,
	svg {
		transition: 0.3s all ease-in-out;
		cursor: pointer;
		&:hover {
			transform: rotate(90deg);
			opacity: 0.8;
		}
	}
}
@media print {
	.not-visible {
		display: none;
	}
	i,
	svg {
		display: none !important;
	}
	.card-list {
		padding: 75px 50px 15px 50px;
		margin: 0px !important;
	}

	.master-card.drink-card .card-list.grid-list .card:nth-child(1),
	.master-card.drink-card .card-list.grid-list .card:nth-child(2),
	.master-card.drink-card .card-list.grid-list .eat:nth-child(3) {
		border-right: 1.5px solid rgba(0, 0, 0, 0.2);
	}
}
