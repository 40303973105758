@import "../../../Style/colors.scss";

.profile {
	.auth-code {
		.onedash-input-container {
			margin: 10px 0px;
		}
		input {
			opacity: 0;
			display: block;
		}
	}

	.password-change {
		.card-content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}
		.password-policy {
			width: 100%;
			max-width: 300px;
			margin: 10px;
			margin-bottom: 60px;

			li {
				transition: 0.15s all ease-in-out;
				margin: 5px 0px;
				&.valid {
					text-decoration-color: $completed;
					text-decoration: line-through;
					color: $completed;
				}
				&.info {
					color: $blue;
				}
			}

			h2 {
				font-size: 1.1rem;
				margin-bottom: 10px;
			}
		}
	}
}
