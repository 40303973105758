@import "./colors.scss";
body,
html {
	background: $pageBackground;
	font-size: 15px;

	font-family: "Ubuntu", sans-serif;
	color: $textColor;
}

li {
	list-style: none;
}
ul {
	margin: 0;
	padding: 0;
}
.bold {
	font-weight: bold;
}

.danger {
	border: 2px solid #c44234;
	background: #c442346e;
	padding: 5px;
	border-radius: 4px;
}
.dialog-bg,
.dialog-container {
	@media screen and (min-width: 768px) {
		left: 80px !important;
		width: calc(100vw - 80px);
	}
}

.btn {
	border-radius: 4px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
	border: none;
	padding: 10px 30px;
	cursor: pointer;
	-webkit-transition: 0.15s all ease-in-out;
	transition: 0.15s all ease-in-out;
	background: #106fdf;
	margin: 10px 0px;
	color: white;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
}

.scrollingDisabled {
	overflow-y: hidden;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.6);
}
::-webkit-scrollbar {
	width: 8px;
}
::-webkit-scrollbar-track {
	display: none;
}

* {
	scrollbar-color: rgba(0, 0, 0, 0.6) rgba(0, 0, 0, 0);
	scrollbar-width: thin;
}

.completed {
	color: $completed;
}
.pending {
	color: $pending;
}
.rejected {
	color: $rejected;
}

.btn {
	border-radius: 4px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
	border: none;
	padding: 10px 30px;
	cursor: pointer;
	-webkit-transition: 0.15s all ease-in-out;
	transition: 0.15s all ease-in-out;
	background: white;
	margin: 0px 10px;
	color: #777777;

	&__positive {
		background: $blue;
		color: white;
	}
}
.add-btn {
	background: #e9e9e9;
	border: none;
	display: inline-block;
	border-radius: 4px;
	color: #393939;
	padding: 12px 30px;
	margin-bottom: 7px;
	-webkit-transition: 0.15s all ease-in-out;
	transition: 0.15s all ease-in-out;
	cursor: pointer;
}

.onedash-style-one .onedash-button:disabled {
	border: 2px solid #e9e9e9;
	pointer-events: none;
	.text {
		color: #393939;
	}
}
