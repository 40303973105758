@import "../../../Style/colors.scss";

.table-manager-page {
	.selection-form {
		width: 100%;
	}
	.onedash-form:not(.submit-form) {
		display: inline-block;
	}
	.onedash-form {
		margin: 0px;
	}

	.table-name-form {
		display: block !important;
		.onedash-input {
			width: 270px;
		}
	}
	.onedash-submit-container {
		display: inline-block !important;
	}
	.onedash-table {
		margin-top: 10px;
		padding-top: 50px;
		border-top: 1px solid $dialogFooterBorder;
	}

	.add-entry {
		background: $secondaryFormButton;
		border: none;
		display: inline-block;
		border-radius: 4px;
		color: $textColor;
		padding: 12px 30px;
		margin-bottom: 7px;
		transition: $fastEaseTransition;
		cursor: pointer;
		&:hover {
			background: $primaryFormButton;
			color: white;
		}
	}
}
