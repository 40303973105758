@import url("https://fonts.googleapis.com/css?family=Karma:300,700");

.roomInvoice {
	/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)

*/
	height: calc(100vh - 40px);

	font-size: 1rem;
	display: flex;
	justify-content: center;
	position: relative;
	background: white;
	color: #2b2c33;
	$maxWidth: 750px;
	$margin: 25px;
	$leftWidth: 200px;
	font-family: "Karma";
	$rightWidth: $maxWidth - 2 * $margin - $leftWidth;

	.paper-watermark {
		background: url("logo-background.png");
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: top;
		opacity: 0.2;
		position: absolute;
		height: calc(100% - 80px);
		width: 100%;
		z-index: 10;
		margin: 40px 0px;
		display: none;
		@media print {
			display: block;
		}
	}

	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
	}

	/* HTML5 display-role reset for older browsers */
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol,
	ul {
		list-style: none;
	}

	blockquote,
	q {
		quotes: none;
	}

	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: "";
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/*

        MAIN CONTENT

  */

	.firstcharacter {
		float: left;
		font-size: 2.9rem;
		line-height: 2.5rem;
		padding-top: 4px;
		padding-right: 8px;
		padding-left: 3px;
	}
	.paragraph {
		margin-top: 10px;
		font-style: italic;
	}

	.logo-container {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	h2 {
		font-weight: normal;
	}
	.logo {
		background: url("logo.png");
		width: 260px;
		height: 32px;
		background-size: cover;
		margin: 50px;
	}
	.main-content {
		width: 100%;
		display: flex;
		height: calc(100% - 125px);
	}
	.left,
	.right {
		margin: 0px $margin;
	}
	.right {
		width: $rightWidth;
		height: calc(100% - 200px);
		display: flex;
		align-items: center;

		.total-table {
			margin-top: 30px;
		}

		table {
			width: 100%;
			thead th {
				text-align: right;
				font-weight: normal;
				font-size: 0.9rem;
				color: #989898;
				text-transform: uppercase;

				&:last-of-type {
					padding-right: 10px;
				}
				&:first-of-type {
					text-align: left;
				}
			}
			.rooms td {
				vertical-align: bottom;
			}
			tbody {
				.dashed-border {
					border-top: 1px dashed #3e3e3e;
				}
				.bold-border {
					border-top: 2px solid #3e3e3e;
				}
				.totalSum {
					font-weight: bold;
					font-size: 1.1rem;
				}

				.dotted-border {
					border-top: 2px dotted #3e3e3e;
				}
				.bottom ul {
					margin: -5px 0px 0px 20px;
					font-size: 0.9rem;
					color: #989898;
				}
				tr {
					border-top: 1px solid #f0f0f0;

					&.bottom td {
						vertical-align: bottom;
					}

					td {
						vertical-align: baseline;
						text-align: right;
						&:first-of-type {
							text-align: left;
						}
						&:last-of-type {
							padding-right: 15px;
						}
					}
					ul {
						padding-bottom: 10px;
					}

					h3 {
						font-size: 0.9rem;
						margin: 10px 0px 0px 10px;
					}
					.gray {
						color: #989898;
					}
					.small {
						font-size: 0.8rem;
					}
					.left-margin {
						margin-left: 10px;
					}
					.room-names ul,
					.netto ul,
					.restaurant-invoice ul {
						margin: -5px 0px 0px 20px;
						font-size: 0.9rem;
						color: #989898;
					}
				}
			}
		}

		.head {
			text-transform: uppercase;
			font-weight: normal;
		}
		.content {
			font-style: italic;
			font-size: 0.9rem;
			color: #989898;
			margin: 5px;
		}
	}
	.header {
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
	}
	.dates {
		width: 200px;
	}
	.left {
		width: $leftWidth;
		height: calc(100% - 50px);
		margin-top: 50px;
		div {
			margin: 30px 0;
			&:first-of-type {
				margin-top: 0;
			}
		}

		ul,
		p {
			margin: 5px;
			font-size: 0.9rem;
			font-style: italic;
			color: #989898;
		}
		li {
			list-style: none;
		}

		h1 {
			font-weight: bold;
			margin-bottom: 30px;
		}
		h2,
		h1 {
			text-transform: uppercase;
		}
	}
	.roomInvoice {
		height: 285mm;
		display: flex;
		justify-content: center;
		position: relative;
	}
	.container {
		max-width: $maxWidth;
	}
	.invoice-text {
		margin-top: 40px;
	}
	.invoice-num {
		.content {
			text-align: right;
			margin-right: 10px;
		}
	}
	.footer {
		position: absolute;
		bottom: 15px;
		font-size: 0.7rem;
		color: #989898;
		text-align: center;
		width: 100%;
		left: 0;
		p {
			margin-left: auto;
			margin-right: auto;
			width: 350px;
		}
	}
	.print-toolbar {
		button {
			border: none;
			height: 70px;
			width: 70px;
			border-radius: 50%;
			box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.31);
			color: white;
			font-size: 2rem;
			transition: 0.3s ease-in-out all;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			&:hover {
				opacity: 0.9;
			}
		}
		.back-button {
			left: 20px;
			top: 20px;
			position: fixed;
		}
		.print-button {
			left: 22px;
			bottom: 20px;
			position: fixed;
		}
		.yellow {
			background: #ffca3c;
		}
		.red {
			background: #e71c1c;
		}
		position: fixed;
	}
}

@media print {
	.print-toolbar {
		display: none;
	}
}
