@import "../../../Style/colors.scss";

.dashboard-navigation {
	width: 80px;
	position: fixed;
	left: 0;
	top: 60px;
	height: calc(100vh - 100px);
	background: $navBackground;
	box-shadow: $navigationShadow;
	z-index: 20;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0px;
	overflow: auto;

	&.loading-finished {
		.loading-indicator::after {
			transform: scale(0.001);
		}
	}
	&.loading-stop-rotation {
		.loading-indicator {
			animation: none;
		}
	}

	& > div {
		ul {
			margin-top: 40px;
			&:nth-child(1) {
				margin-top: 0px;
			}

			.app-name {
				color: $navAppNameColor;
				font-size: 0.7rem;
				width: 60px;
				text-align: center;
			}
		}
	}
	li {
		background: $navElementBackground;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		margin: 12px 10px;

		i,
		svg {
			color: $navIconColor;
			transition: $baseEaseTransition;
		}
		.active .loading-indicator {
			display: block;
		}

		.navigation-route-name {
			text-decoration: none;
			display: none;
			color: $navAppNameColor;
		}

		.loading-indicator {
			animation: spin 1.5s linear infinite;
			position: absolute;
			width: 40px;
			height: 40px;
			z-index: -1;
			border: 2px solid $navItemLoading;
			border-radius: 50%;
			display: none;

			&:after {
				transition: 3s all ease-in-out;
				content: "";
				width: 91%;
				height: 91%;
				border-radius: 30%;
				background: linear-gradient(
					45deg,
					$navBackground 0%,
					$navBackground 40%,
					transparentize($navBackground, 0.3) 60%,
					transparentize($navBackground, 1) 100%
				);
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(-10%, -5%);
			}
		}

		a,
		button {
			background: none;
			border: none;
			z-index: 2;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			cursor: pointer;
			color: white;

			&:hover > i,
			&:hover > svg {
				color: $navItemHoverColor;
			}
		}
	}

	@media (max-width: 768px) {
		width: calc(100% - 50px);
		box-shadow: none;
		position: relative;
		height: calc(100% - 50px);
		top: 0;
		padding: 0px 25px;
		background: none;
		padding-top: 50px;

		div ul .app-name {
			font-size: 1rem;
			width: auto;
		}
		div ul {
			margin-top: 30px;
		}

		i,
		svg {
			margin-right: 25px;
		}

		li {
			width: auto;
			background: none;
			margin: 5px;
			a {
				font-size: 0.85rem;
				justify-content: flex-start;
				&.active .navigation-route-name {
					color: lighten($navItemHoverColor, 20);
				}
			}
			.navigation-route-name {
				display: inline-block;
			}
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 0;
			padding: 5px;
		}
		li .loading-indicator {
			width: 30px;
			height: 30px;
			margin-left: -12px;
		}
	}
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 20px;
	top: 15px;

	@media print {
		display: none !important;
	}
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: $navBackground;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
	position: fixed;
	height: 100%;
}

/* General sidebar styles */
.bm-menu {
	background: $navBackground;
	font-size: 1.15em;
	&:focus {
		outline: none;
	}
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: $navBackground;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
	&:focus {
		outline: none;
	}
}

/* Individual item */
.bm-item {
	display: inline-block;
	&:focus {
		outline: none;
	}
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}

@media print {
	.dashboard-navigation {
		display: none !important;
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
