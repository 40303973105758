@import "../../../Style/colors.scss";

.fundsachen {
	margin-top: 40px;
	max-height: 80vh;
	overflow-y: auto;
	overflow-x: hidden;
	.Collapsible {
		width: calc(100% - 4px);
	}

	.thumbnail-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 120px;
		border: 1px solid $dividerColor;
		margin: 5px;
		transition: 0.15s all ease-in-out;
		&:hover {
			opacity: 0.7;
		}
	}

	.thumbnail {
		max-width: 100px;
		max-height: 100px;
	}
	.fundsache {
		display: flex;
		flex-wrap: wrap;

		.thumbail-list {
			display: flex;
			flex-wrap: wrap;
			margin-left: 10px;
			margin-top: 10px;
			flex-basis: 100%;
		}

		& > div {
			margin: 0px 20px;
			flex-basis: calc(50% - 40px);
		}
		li {
			.title {
				font-weight: bold;
				font-size: 1.1rem;
				margin-bottom: 5px;
			}
			.value {
				margin-left: 3px;
			}
			margin-bottom: 10px;
		}
	}
	.thumbnail-item {
		display: inline-block;
	}

	.finder-image-checkbox {
		.onedash-input-container {
			width: 100%;
		}
	}
}

.image-tools {
	width: 80%;
	max-width: 600px;
	display: flex;
	justify-content: space-between;
}
#viewerBackdrop > div > figure > img {
	transition: 0.3s ease-in-out all !important;
	max-width: calc(100vh - 90px) !important;
}

.image-tools-wrapper {
	pointer-events: none;
	position: fixed;
	left: 0px;
	top: 10px;
	z-index: 10000;
	width: 100%;
	display: flex;
	justify-content: center;
	button {
		cursor: pointer;
		pointer-events: all;
	}
}
