@import "../../../Style/colors.scss";

.Collapsible {
	border: 2px solid $dividerColor;
	border-radius: 3px 3px 0px 0px;
	border-bottom: none;
	width: 100%;

	&__trigger {
		width: calc(100% - 40px);
		padding: 10px 20px;
		display: block;
		cursor: pointer;

		&.is-open {
			border-bottom: 1px solid $dividerColor;
		}
		.trigger {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.title {
				font-size: 1.1rem;
				font-weight: bold;
			}
			button {
				margin: 0xp 5px;
			}
		}
	}

	&__contentInner {
		padding: 20px;
		min-height: 200px;
	}

	&:last-of-type {
		border-radius: 3px;
		border-bottom: 2px solid $dividerColor;
	}
}
