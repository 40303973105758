@import "../../../Style/colors.scss";

.card-designer {
	*:focus {
		outline: none;
	}
	background-attachment: fixed;
	min-height: 100vh;

	/* **************************************************** */
	/*                  Page Sizes                          */

	.pages {
		padding-top: 30px;
		padding-bottom: 30px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&.wrap-enabled {
			flex-wrap: wrap;
			flex-direction: row;
		}
		.button-corner {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 3;
			height: 40px;
			width: 40px;
			background: #c9c9c9;
			display: flex;
			box-shadow: inset 1px -1px 8px rgba(0, 0, 0, 0.5);
			align-items: center;
			justify-content: center;
			border-radius: 0px 0px 0px 100%;
			i,
			svg {
				margin-left: 8px;
				margin-top: -7px;
				color: white;
			}
			cursor: pointer;
			transition: 0.3s all ease-in-out;
			&:hover {
				background: #7fcc76;
			}
		}
		article {
			background: white;
			box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
			margin: 20px;
			position: relative;
			page-break-inside: avoid;

			&.not-editable {
				opacity: 0.95;
				.card-list {
					pointer-events: none;
				}
				.paper-watermark {
					display: block !important;
				}
				.price-buttons {
					display: none;
				}
			}

			.paper-watermark {
				background: url("logo-background.png");
				background-size: 80%;
				background-repeat: no-repeat;
				background-position: top;
				opacity: 0.3;
				position: absolute;
				height: calc(100% - 80px);
				width: 100%;
				z-index: 10;
				margin: 40px 0px;
				display: none;
			}
		}
		article[data-size="A4"] {
			height: 296.75mm;
			width: 210mm;

			@media (max-width: 768px) {
				transform: scale(0.5);
			}

			@media print {
				transform: none !important;
			}

			.paper-watermark {
				background-size: 80%;
			}
		}
		article[data-size="square"] {
			height: 207mm;
			width: calc(210mm - 4px);
			padding: 0px 2px;

			.paper-watermark {
				background-size: 60%;
			}
		}
	}
}
.dialog {
	.basic-input {
		padding-bottom: 0;
	}
	.dialog-headding {
		color: $h2Color;
		font-weight: lighter;
		margin-bottom: 20px;
		border-bottom: 1px solid $h2BorderColor;
		padding: 0px 0px 2.5px 5px;
	}
	.masterCardSelection-group {
		display: flex;
		.select-box {
			width: calc(100% - 44px);
		}
		button {
			flex-shrink: 1;
			background: none;
			border: none;
			color: white;
			cursor: pointer;
			transition: 0.3s all ease-in-out;
			margin-left: 10px;

			&:hover {
				opacity: 0.7;
			}
			&:active {
				outline: none;
			}
		}
	}
}
@media print {
	.card-designer {
		background: white;
		.pages {
			padding-bottom: 0px;
			padding-top: 0px;
			article {
				box-shadow: none;
				margin: 0;
				.button-corner {
					display: none;
				}
			}
			article[data-size="square"] {
				border-left: 1px solid #979797;
				border-right: 1px solid #979797;
			}
		}
	}
	.paper-watermark {
		display: block !important;
	}

	article {
		opacity: 1 !important;
	}
}
